import React, { Component }  from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import Button from '@components/Core/Button';
import AddressAutoComplete from '@components/Forms/AddressAutoComplete';
import DateRangePicker from '@components/Forms/DateRangePicker';
import HousemateSearchUtility from '@utilities/HousemateSearchUtility';
import ListingSearchUtility from '@utilities/ListingSearchUtility';
import SearchFilterUtility from '@utilities/SearchFilterUtility';
import StringUtility from '@utilities/StringUtility';

class SearchBlock extends Component {
  state = {
    city: this.props.city,
    citySearchText: this.props.citySearchText,
    isLoadingSearchPage: false,
    moveIn: null,
    moveOut: null,
    state: this.props.state,
  };

  componentDidUpdate(prevProps) {
    const { citySearchText, city, state } = this.props;

    if (prevProps.citySearchText !== citySearchText) {
      this.setState({
        citySearchText,
      });
    }

    if (prevProps.city !== city) {
      this.setState({
        city,
      });
    }

    if (prevProps.state !== state) {
      this.setState({
        state,
      });
    }
  }

  search = async () => {
    let { checkDefaultLocationSet, router, isHousemateSearch, filters } = this.props;
    let { citySearchText, moveIn, moveOut, state, city } = this.state;

    await this.setState({
      isLoadingSearchPage: true,
    });

    if (!state || !city) {
      if (citySearchText && citySearchText.length > 0) {
        const firstPrediction = await this.addressAutoComplete.getFirstPrediction(citySearchText);

        if (firstPrediction && firstPrediction.city && firstPrediction.state) {
          state = StringUtility.formatUrlSlug(firstPrediction.state);
          city = StringUtility.formatUrlSlug(firstPrediction.city);
        }
      }

      // If the default location is set then send user to listing search index
      if (checkDefaultLocationSet && filters.get('defaultLocationSet')) {
        return router.push('/c');
      }

      state = filters.get('stateSlug');
      city = filters.get('citySlug');
    } else {
      state = StringUtility.formatUrlSlug(state);
      city = StringUtility.formatUrlSlug(city);
    }

    this.setState({
      city,
      state,
    });

    if (moveIn && moveOut) {
      filters = filters.merge({
        start: moveIn,
        end: moveOut,
      });
    }

    filters = filters.set('bounds', null);

    const query = SearchFilterUtility.getListingSearchQueryFilterParams(filters);

    const pathStructure = {
      pathname: isHousemateSearch ? HousemateSearchUtility.citySearchLinkTemplate : ListingSearchUtility.buildSearchLinkTemplate(state, city),
      query,
    };

    const path = {
      pathname: isHousemateSearch ? HousemateSearchUtility.buildSearchLink(state, city) : ListingSearchUtility.buildSearchLink(state, city),
      query,
    };

    router.push(pathStructure, path, { shallow: true });
  }

  // Update the in state city search text on change
  handleLocationInputChange = (key, event) => {
    const { value } = event.target;

    this.setState({
      citySearchText: value,
    });
  }

  // Update the city/state of the filters on address select
  handleLocationSelect = (result) => {
    if (!result.city && !result.state && !result.coords) {
      return;
    }

    const searchPageType = result.city && result.state
      ? SearchFilterUtility.searchPageTypes.city
      : SearchFilterUtility.searchPageTypes.state;

    this.setState({
      city: result.city,
      state: result.state,
      citySearchText: SearchFilterUtility.getSearchText(searchPageType, result.city, result.state, result.state_short),
    });
  }

  // Update the move in/out when selected from datepicker
  updateMoveDate = (moveType, date) => {
    this.setState({
      [moveType]: date,
    });
  }

  renderForm = () => {
    let { citySearchText, isLoadingSearchPage, moveIn, moveOut } = this.state;
    const { formClassName, dateFormat, isInline, isHousemateSearch, searchBlockId } = this.props;

    return (
      <>
        <div className={`b--rounded m-horizontal--auto p-around--large ${formClassName}`}>
          <div className="layout-gt-xs-row layout-align-start-end">
            <div className="flex-gt-xs-40 m-right--small-gt-xs">
              <AddressAutoComplete
                addressInputId={`${searchBlockId}-address-input`}
                handleAddressChange={this.handleLocationSelect}
                handleAddressItemChange={this.handleLocationInputChange}
                label="Location"
                name="city"
                placeholder="City or town"
                ref={addressAutoComplete => this.addressAutoComplete = addressAutoComplete}
                regionsOnly
                value={citySearchText}
              />
            </div>
            <div className="w--100p m-top--medium-lt-sm">
              <DateRangePicker
                dateFormat={dateFormat}
                dateSelected={this.updateMoveDate}
                identifier={`${searchBlockId}-date-picker`}
                moveIn={moveIn}
                moveInPlaceholder="Select"
                moveOut={moveOut}
                moveOutPlaceholder="Select"
              />
            </div>
            { isInline &&
              <Button
                className="w--100p-xs primary m-top--large-lt-sm m-left--small-gt-xs"
                id={`${searchBlockId}-search`}
                isLoading={isLoadingSearchPage}
                onClick={this.search}
              >
                Search
              </Button>}
          </div>
          { !isInline &&
            <Button
              className="w--100p primary m-top--medium"
              id={`${searchBlockId}-search`}
              isLoading={isLoadingSearchPage}
              onClick={this.search}
            >
              { isHousemateSearch ? 'Find housemates' : 'Search rentals' }
            </Button>}
        </div>
      </>
    );
  }

  render () {
    const { description, title, wrapperClassName } = this.props;

    return description && title ? (
      <div className={`${wrapperClassName} p-vertical--x-large-lt-sm p-vertical--xxx-large-gt-xs`}>
        <div className="mw--md m-horizontal--auto p-horizontal--large p-horizontal--x-large-gt-xs">
          <div className="ta-center m-bottom--large">
            <h2>{ title }</h2>
            <p className="m-top--small">{ description }</p>
          </div>
          { this.renderForm() }
        </div>
      </div>
    ) :
      this.renderForm();
  }
}

SearchBlock.propTypes = {
  // Required
  filters: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  searchBlockId: PropTypes.string.isRequired,
  // Optional
  checkDefaultLocationSet: PropTypes.bool,
  formClassName: PropTypes.string,
  city: PropTypes.string,
  citySearchText: PropTypes.string,
  dateFormat: PropTypes.string,
  description: PropTypes.string,
  isInline: PropTypes.bool,
  isHousemateSearch: PropTypes.bool,
  state: PropTypes.string,
  title: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

SearchBlock.defaultProps = {
  checkDefaultLocationSet: true,
  city: null,
  citySearchText: null,
  dateFormat: 'MMMM d',
  description: '',
  formClassName: 'bg--gray-3',
  isInline: true,
  isHousemateSearch: false,
  state: null,
  title: '',
  wrapperClassName: '',
};

const mapStateToProps = (state) => ({
  filters: state.get('Search').get('filters'),
});

export default withRouter(
  connect(mapStateToProps, {})(SearchBlock)
);
