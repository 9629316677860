import StringUtility from '@utilities/StringUtility';

const citySearchLinkTemplate = '/h/[stateSlug]/[citySlug]';

const buildSearchLink = (state, city) => {
  const stateSlug = StringUtility.formatUrlSlug(state);
  const citySlug = StringUtility.formatUrlSlug(city);

  return `/h/${stateSlug}/${citySlug}`;
};

export default {
  buildSearchLink,
  citySearchLinkTemplate,
};
