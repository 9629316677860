import React from 'react';
import { FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon } from '@components/Icons';

const SocialLinks = () => (
  <div className="layout-row layout-align-start-center layout-wrap">
    <div className="m-top--x-small m-right--x-small" id="footer-facebook">
      <a aria-label="Kopa Facebook page" href="https://www.facebook.com/kopahq" rel="noopener noreferrer" target="_blank">
        <FacebookIcon className="icon--20 m-right--xx-small" />
      </a>
    </div>
    <div className="m-top--x-small m-right--x-small" id="footer-instagram">
      <a aria-label="Kopa Instagram page" href="https://www.instagram.com/kopahq/" rel="noopener noreferrer" target="_blank">
        <InstagramIcon className="icon--20 icon--black m-right--xx-small" />
      </a>
    </div>
    <div className="m-top--x-small m-right--x-small" id="footer-twitter">
      <a aria-label="Kopa Twitter page" href="https://twitter.com/kopahq" rel="noopener noreferrer" target="_blank">
        <TwitterIcon className="icon--20 m-right--xx-small" />
      </a>
    </div>
    <div className="m-top--x-small m-right--x-small" id="footer-linkedin">
      <a aria-label="Kopa LinkedIn page" href="https://www.linkedin.com/company/kopahq" rel="noopener noreferrer" target="_blank">
        <LinkedInIcon className="icon--20 m-right--xx-small" />
      </a>
    </div>
  </div>
);

export default SocialLinks;
