import React from 'react';
import Link from 'next/link';
import TextLoop from 'react-text-loop';
import PropTypes from 'prop-types';
import SocialLinks from '@components/Footer/SocialLinks';
import { CircleDollarIcon, CompanyLogoIcon, HeartIcon } from '@components/Icons';

const FOOTER_CITIES = [{
  slug: 'san-francisco',
  name: 'San Francisco',
  state: 'california',
}, {
  slug: 'new-york',
  name: 'New York City',
  state: 'new-york',
}, {
  slug: 'los-angeles',
  name: 'Los Angeles',
  state: 'california',
}, {
  slug: 'seattle',
  name: 'Seattle',
  state: 'washington',
}, {
  slug: 'boston',
  name: 'Boston',
  state: 'massachusetts',
}, {
  slug: 'philadelphia',
  name: 'Philadelphia',
  state: 'pennsylvania',
}, {
  slug: 'dallas',
  name: 'Dallas',
  state: 'texas',
}, {
  slug: 'nashville',
  name: 'Nashville',
  state: 'Tennessee',
}];

const Footer = ({ wrapperClassName }) => (
  <footer className={`bg--cover footer relative z--1 p-top--x-large ${wrapperClassName}`}>
    <div className="mw--md m-horizontal--auto p-around--large p-around--x-large-gt-xs layout-gt-xs-row layout-align-gt-xs-start-start layout-wrap">
      <div className="p-right--medium flex-sm-100 flex-gt-sm layout-column">
        <Link aria-label="Watson Holmes home" className="hover-none" href="/">

          <CompanyLogoIcon className="block h--48 w--auto" />

        </Link>
        <p className="m-top--small">Get out there. Find your place.</p>
      </div>
      <div className="m-top--large-lt-md p-right--medium flex-sm-50 flex-gt-sm-15 layout-column">
        <h3 className="m-bottom--small">Hosting</h3>
        <Link className="black m-top--x-small" href="/host">
          Become a Host
        </Link>
        <Link className="black m-top--x-small" href="/direct-booking-pro">
          Direct Booking Pro
        </Link>
        <Link className="inline-flex black m-top--x-small" href="/refer-host">
          Refer a Host<CircleDollarIcon className="icon--16 m-left--xx-small" />

        </Link>
      </div>
      <div className="m-top--x-large-lt-md p-right--medium flex-sm-50 flex-gt-sm-15 layout-column">
        <h3 className="m-bottom--small">Renting</h3>
        <Link className="black m-top--x-small" href="/c">
          Browse Rentals
        </Link>
        <Link className="black m-top--x-small" href="/housemate-finder">
          Find Roommates
        </Link>
        <Link className="black m-top--x-small" href="/for-business">
          For Business
        </Link>
        <Link className="black m-top--x-small" href="/for-universities">
          For Universities
        </Link>
        <Link className="inline-flex black m-top--x-small" href="/refer-renter">
          Refer a Renter<CircleDollarIcon className="icon--16 m-left--xx-small" />

        </Link>
      </div>
      <div className="m-top--x-large-lt-md p-right--medium flex-sm-50 flex-gt-sm-15 layout-column">
        <h3 className="m-bottom--small">Resources</h3>
        <Link className="black m-top--x-small" href="/about">
          About Us
        </Link>
        <Link className="black m-top--x-small" href="/careers">
          Careers
        </Link>
        <a className="black m-top--x-small" href="https://www.kopa.co/blog" rel="noopener noreferrer" target="_blank">Blog</a>
        <Link className="black m-top--x-small" href="/partnerships">
          Partner Program
        </Link>
        <a className="black m-top--x-small" href="/contact">Contact Us</a>
        <a className="black m-top--x-small" href="https://help.kopa.co/hc/en-us/" rel="noopener noreferrer" target="_blank">Help Center</a>
        <a className="black m-top--x-small" href="/sitemap">Sitemap</a>
      </div>
      <div className="m-top--x-large-lt-md p-right--medium-gt-xs flex-sm-50 flex-gt-sm-15 layout-column">
        <h3 className="m-bottom--small">Top Cities</h3>
        { FOOTER_CITIES.map((city) => (
          <Link
            className="black m-top--x-small"
            href={`/c/${city.state}/${city.slug}`}
            key={city.slug}
          >
            { city.name }
          </Link>
        ))}
      </div>
    </div>
    <div className="mw--md m-horizontal--auto b-top--xs b--gray-2 p-around--large p-horizontal--x-large-gt-xs p-vertical--medium-gt-xs layout-gt-sm-row layout-align-gt-sm-space-between-center">
      <div className="meta layout-row layout-align-start-center layout-wrap">
        <span className="meta dot-separator">© Kopa 2021</span>
        <div className="layout-row layout-align-start-center">
          Made with
          <HeartIcon className="icon--16 icon--hue-2 inline-block m-horizontal--xx-small" />
        </div>
        <div className="layout-row layout-align-start-center">
          by people from
          <TextLoop className="m-left--xx-small" interval="2000" springConfig={{stiffness: 500, damping: 26}}>
            <span>America</span>
            <span>Canada</span>
            <span>Pakistan</span>
          </TextLoop>
        </div>
      </div>
      <div className="m-top--medium-lt-sm layout-row layout-align-start-center layout-wrap">
        <span className="dot-separator" id="footer-tos">
          <Link className="meta roman black" href="/terms">
            Terms of Service
          </Link>
        </span>
        <span className="m-right--medium" id="footer-privacy">
          <Link className="meta roman black" href="/privacy">
            Privacy Policy
          </Link>
        </span>
        <SocialLinks />
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  wrapperClassName: PropTypes.string,
};

Footer.defaultProps = {
  wrapperClassName: '',
};

export default Footer;
